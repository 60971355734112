.table__border{
    border: 1px solid var(--table-color);
    /* Shadow/md */

    box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
}

.table__borderless{
    border: 1px solid var(--table-color);
    /* Shadow/md */

    /* box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06); */
    /* border-radius: 8px; */
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}