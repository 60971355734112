.lds-dual-ring {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid var(--primary);
  border-color: var(--primary) transparent var(--primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_spin{
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
