.dashboard_button {
    font-size: 0.9rem;
    border-radius: 8px;
    padding: 0.8rem 2.2rem;
    cursor: pointer;
}

@media (min-width: 768px) {
    .dashboard_button {
        padding: 0.8rem 2.5rem;
    }
  }

  @media (max-width: 400px) {
    .dashboard_button {
        font-size: 0.8rem;
        padding: 0.6rem 1.5rem;
    }
  }

.dashboard_button-shadow{
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}

.dashboard_button_border{
    background: #FFFFFF;
    /* Gray/300 */

    border: 1px solid var(--button-border-color);
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}