.react-datepicker-wrapper{
    width:100%;
}

.dropzone{
    border-radius: 8px ;
    border: 1px dashed #E4E7EC ;
    background: #FFF ;
}

.drop-border{
    border-radius: 5px;
    border: 1px solid #D0D5DD;
    background: #FFF;
}

.addNewTicket{
    border-radius: 5px;
    background: #F5F5F5;
}