/* Fonts imports */
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');
@font-face {
    font-family: 'andarilho';
    src: url('./assets/font/andarilho-font.ttf');
}

/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap'); */


/* Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;


.event-input {
  @apply border border-button-border placeholder-table-color focus:border-button-border focus:outline-none rounded py-2 px-2;
}

/* Colors declaration */
:root {
    --primary: #FD015C;
    --primary-dark:#CD0053;
    --table-color:#E4E7EC;
    --button-border-color: #D0D5DD;
    --black: #101828;
    --dashboard-bg :#FCFCFD;
    --dashboard-black: #220E09B2;
    --dashboard-black-70: #5D4F4B;
    --white: #ffffff;
    --primary-green: #00B6AA;
    --primary-green-dark: #00B6AA;
    --red: #F01717;
    --pink: #EE46BC;
    --purple: #B400AD;
    --yellow: #FFC225;
    --blue: #20A1FF;
    --white-fade: #7A7E86;
    --link-fade: #AEB9DE;
    --input-fade: #E9E9E9;
}


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

a {
    text-decoration: none !important;
}
a:focus {
    color: var(--primary);
}

html {
    overflow-x: hidden;
    scroll-behavior: smooth;

    /*1rem = 10px; 10px/16px = 62.5% */
    /* font-size: 62.5%; */
}

body {
    font-family: 'Inter', 'san-serif';
    box-sizing: border-box;
    color: var(--black);
    overflow: hidden;
    background-color: white;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    height: 2px !important;
    width: 2px !important;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border: 1px solid #555555;
}

::selection {
    background-color: var(--primary);
    color: white;
}

/* Font declaration */

.font-andarilho {
    font-family: "andarilho";
}

.font-sora{
  font-family: "Sora";
}

.font-inter{
  font-family: "Inter"
}

.web-border-16{
  border-radius: 16px;
}

.web-border-8{
  border-radius: 8px;
}

.input-form{
  /* White */

  background: #FFFFFF;
  /* Gray/300 */

  border: 1px solid #D0D5DD !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

.css-13cymwt-control{
  border: 1px solid #D0D5DD !important;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
  padding: 0.25rem;
}

.flex__center{
  display: flex;
  justify-content: center;
  align-items:center;
}

.flex__start{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.label-switch {
  cursor: pointer;
  text-indent: -9999px;
  width: 2.5rem;
  height: 1.5rem;
  background: var(--white-fade);
  display: block;
  border-radius: 100px;
  position: relative;
}

.label-switch:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 1.4rem;
  height: 1.4rem;
  background: #fff;
  box-shadow: 0px 0px 2.04287px rgba(0, 0, 0, 0.05), 
  0px 2.04287px 0.680956px rgba(0, 0, 0, 0.0510643), 
  0px 1.36191px 1.36191px rgba(0, 0, 0, 0.1), 
  0px 2.04287px 2.04287px rgba(0, 0, 0, 0.05);
  border-radius: 90px;
  transition: 0.3s;
}

.check-switch:checked + .label-switch {
  background: var(--primary-green-dark);
}
 
.check-switch:checked + .label-switch:after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.label-switch:active:after {
  width: 1.6rem;
}


.text__landing__height{
  line-height: 72px !important;
 }

.border__radius-5{
  border-radius: 5px;
}

@media (min-width: 1280px) {
  .text__landing__height{
    line-height: 72px !important;
   }
}

@media (min-width: 1024px) {
  .text__landing__height{
    line-height: 62px !important;
   }
}

@media (max-width: 950px) {
  .text__landing__height{
    line-height: 45px !important;
   }
}

.theme__border{
  border: 1px solid #D0D5DD;
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.scrollbar-none{
  scrollbar-width: none !important;
}

.padding-3 {
  padding: 0.75rem !important/* 12px */;
}