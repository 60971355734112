.section{
    min-height: 100vh;
    height: min-content;
}

@media (min-width: 1280px) {
    .section {
        min-height: 70vh;
    }
}

@media (max-width: 400px) {
    .xs_flex_col {
        flex-direction: column !important;
    }
}


.bg-green-opacity{
    background: rgba(0, 182, 170, 0.08);
}

.padding_below_none{
    padding-bottom: 0px !important;
}

.group-border{
    border: 1px solid var(--table-color);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.ticket-border{
    border: 1px solid #F2F2F2;
    border-radius: 5px;
}

.ticket-shadow{
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
}

.input-fade{
    background-color: var(--input-fade);
}
.navBgFilter{
    background: rgba(255, 255, 255, 0.08);
}
.navFilter:hover{
    background: rgba(255, 255, 255, 0.08);
}

.search-payments{
    border: 1px solid #D0D5DD;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}